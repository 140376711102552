<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="orderDetails">
    <!-- 表头 -->
    <orderDetailHeader id="orderHeader" ref="orderHeader" :ishandler="Ishandler" :order-i-d="OrderID" />
    <!-- 物料明细 -->
    <orderdetailMaterialList id="orderMaterialList" ref="orderMaterialList" :ishandler="Ishandler" :order-i-d="OrderID" />
    <!-- 销售凭证 -->
    <salesOrder id="orderSaleList" ref="orderSaleList" :ishandler="Ishandler" :order-i-d="OrderID" />
    <!-- 交货单 -->
    <deliveryOrder id="orderDeliveryList" ref="orderDeliveryList" :ishandler="Ishandler" :order-i-d="OrderID" />
    <!-- 右侧导航 -->
    <orderNavigation ref="orderNavigation" />
    <!-- 底部操作 -->
    <!-- <div v-if="Ishandler === '999'" class="divbottom p-tb-20">
      <el-button class="m-right-20" type="primary" size="small" round @click="openCretateDelivery">生成交货单</el-button>
      <el-button class="m-right-20" type="primary" size="small" round @click="dealTransPurchase">转采购</el-button>
      <el-button class="m-right-20" type="primary" size="small" round plain @click="comfirmColseOrder()">关闭订单</el-button>
    </div> -->
    <!-- 订单解锁 -->
    <el-dialog title="订单被锁定" :visible.sync="OrderResoveVisibel" width="400px" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <div class="lockbox t_line_s">
        <span>
          订单 {{ orderResovleInfo.OrderID }} 已被 {{ orderResovleInfo.NickName }} 锁定<br>
          锁定时间 {{ orderResovleInfo.WriteTime }}
        </span></div>
      <div class="caozuo t-right p-right-20 p-top-20 t_line_s">
        <el-button class="ModelBttton1-blue-40" type="primary" round @click="comfirmOrderResove">解锁并锁定订单</el-button>
      </div>
    </el-dialog>
    <!-- 转采购 -->
    <el-dialog class="DepartmentBox" title="转采购" center :visible.sync="transTopurchaseVisbel" width="800px" :close-on-click-modal="false">
      <el-form label-position="right" label-width="130px" :inline="true" :model="TransToPurchaseData" size="mini">
        <el-form-item label="期望到货日期：" style="width:48%">
          <el-date-picker
            v-model="TransToPurchaseData.ArrivalDate"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="效期要求：" style="width:48%">
          <el-select v-model="TransToPurchaseData.ExpireDateFlag" placeholder="">
            <el-option :value="3" label="大于三个月" />
            <el-option :value="4" label="大于四个月" />
            <el-option :value="5" label="大于五个月" />
            <el-option :value="6" label="大于六个月" />
            <el-option :value="8" label="大于八个月" />
            <el-option :value="12" label="大于十二个月" />
          </el-select>
        </el-form-item>
        <el-form-item label="批号要求：" style="width:48%">
          <el-radio v-model="TransToPurchaseData.LotNoRequire" :label="10" @change="changeLot(10)">同上次批号</el-radio>
          <el-radio v-model="TransToPurchaseData.LotNoRequire" :label="20" @change="changeLot(20)">本次同批号</el-radio>
        </el-form-item>
        <el-form-item label="入库仓位：" style="width:48%">
          <el-select v-model="TransToPurchaseData.StockCode" placeholder="">
            <!-- <el-option :value="3000" label="3000" /> -->
            <!-- <el-option :value=2 label="发送到发法定分散的送到" /> -->
            <el-option v-for="(item, index) in locatList" :key="index" :label="item.StockCode" :value="item.StockCode" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否直送医院：" style="width:48%">
          <el-radio v-model="TransToPurchaseData.IsDirectSending" :label="1" @change="chagesend(1)">是</el-radio>
          <el-radio v-model="TransToPurchaseData.IsDirectSending" :label="0" @change="chagesend(0)">否</el-radio>
        </el-form-item>
        <el-form-item label="通知处理人：" style="width:48%">
          <el-input :value="TransToPurchaseData.NoticeUserName.join(',')" placeholder="请选择通知人" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="oprenSelectContractUser" />
          </el-input>
        </el-form-item>
        <div class="flex">
          <div style="width: 117px;text-align: right">收货地址：</div>
          <div style="flex: 1" class="m-left-10 m-bottom-10">
            <span>{{ TransToPurchaseData.sendAddress }}</span>
            <span>
              <el-link v-if="TransToPurchaseData.IsDirectSending === 0" class="ModelBttton1-white-24" :underline="false" type="primary" @click="selectAddress()">
                选择地址
              </el-link>
            </span>
          </div>
        </div>
        <!--      display: 'flex',height: TransToPurchaseData.sendAddress.length >= 70 ? '70px' : TransToPurchaseData.sendAddress.length >= 50 ? '60px' :'auto'  -->
        <!--        <el-form-item-->
        <!--          label="收货地址："-->
        <!--          style="width: 100%;display: flex;flex-wrap: nowrap;"-->
        <!--        >-->
        <!--          <span>{{ TransToPurchaseData.sendAddress }}</span>-->
        <!--          <span>-->
        <!--            <el-link v-if="TransToPurchaseData.IsDirectSending === 0" class="ModelBttton1-white-24" :underline="false" type="primary" @click="selectAddress()">-->
        <!--              选择地址-->
        <!--            </el-link>-->
        <!--          </span>-->
        <!--        </el-form-item>-->
        <el-form-item class="shdz" label="备注：" style="width:100%">
          <el-input
            v-model="TransToPurchaseData.Remark"
            size="mini"
            placeholder=""
          />
        </el-form-item>
      </el-form>
      <el-table
        :header-cell-style="{background:'rgb(247,248,252)'}"
        :data="TransToPurchaseData.tableList"
        tooltip-effect="dark"
        show-header
        border
        class="m-bottom-20"
      >
        <el-table-column
          prop="InvCode"
          label="物料编号"
        />
        <el-table-column
          prop="ManuPartNum"
          label="原厂编号"
          show-overflow-tooltip
        />
        <el-table-column
          prop="MaterialName"
          label="物料名称"
          width="200"
          show-overflow-tooltip
        />
        <el-table-column
          prop="Specification"
          label="规格"
          width="200"
          show-overflow-tooltip
        />
        <el-table-column
          prop="BrandName"
          label="品牌"
          width="150"
          show-overflow-tooltip
        />
        <el-table-column
          prop="MRCStockUnitN"
          label="单位"
          show-overflow-tooltip
        />
        <el-table-column
          prop="Num"
          label="原数量"
          show-overflow-tooltip
        />
        <el-table-column
          label="最大可转采数量"
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.PartNum }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="cgsl"
          label="采购数量"
          show-overflow-tooltip
          fixed="right"
        >
          <template slot-scope="scope">
            <el-input v-model="scope.row.cgsl" @blur="checkedcgsl(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column
          prop="LotNum"
          label="批号"
          show-overflow-tooltip
          fixed="right"
        >
          <template slot-scope="scope">
            <el-input v-model="scope.row.LotNum" :disabled="true" />
          </template>
        </el-table-column>
        <el-table-column
          prop="ExpiredDate"
          label="效期"
          show-overflow-tooltip
          fixed="right"
          width="125"
        >
          <template slot-scope="scope">
            <el-input v-model="scope.row.ExpiredDate" :disabled="true" />
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer caozuo">
        <el-button @click="transTopurchaseVisbel = false">关 闭</el-button>
        <el-button type="primary" @click="comfirmTransTopur">提 交</el-button>
      </div>
    </el-dialog>
    <!-- 创建交货单 -->
    <el-dialog class="DepartmentBox" title="创建交货单" :visible.sync="createDeliveryVisbel" width="80%" :close-on-click-modal="false">
      <el-table
        v-loading="createDeliveryloading"
        :header-cell-style="{background:'rgb(247,248,252)'}"
        :data="createDeliveryList"
        tooltip-effect="dark"
        show-header
        border
      >
        <el-table-column
          label="序号"
        >
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="InvCode"
          label="物料编号"
        />
        <el-table-column
          prop="InvName"
          label="物料名称"
          show-overflow-tooltip
        />
        <el-table-column
          prop="Specification"
          label="规格"
          show-overflow-tooltip
        />
        <el-table-column
          prop="BrandName"
          label="品牌"
          show-overflow-tooltip
        />
        <el-table-column
          prop="MRCStockUnitN"
          label="单位"
          show-overflow-tooltip
        />
        <el-table-column
          prop="MaterialStorageConditionName"
          label="存储条件"
          show-overflow-tooltip
        />
        <el-table-column
          prop="LotNum"
          label="批号"
          show-overflow-tooltip
        />
        <el-table-column
          prop="ExpiredDate"
          label="效期"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ $minCommon.formatDate1(scope.row.ExpiredDate, 'yyyy-MM-dd') }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="Location"
          label="所属仓库"
          show-overflow-tooltip
        />
        <el-table-column
          prop="StockNumV2"
          label="库存"
          show-overflow-tooltip
        />
        <el-table-column
          prop="num"
          label="数量"
          show-overflow-tooltip
        />
        <el-table-column
          prop="NumSend"
          label="已交货数量"
          show-overflow-tooltip
          width="100"
        />
        <el-table-column
          prop="ContactPrice"
          label="单价"
          show-overflow-tooltip
        />
        <el-table-column
          prop=""
          label="小计"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.ContactPrice * scope.row.num }}
          </template>
        </el-table-column>
      </el-table>
      <div class="nowrap m-tb-20 flex p-lr-20">
        <label class="Name">备注：</label><el-input v-model="createDeliveryReason" size="small" />
      </div>
      <div slot="footer" class="caozuo">
        <el-button @click="createDeliveryVisbel = false">关 闭</el-button>
        <el-button type="primary" @click="confirmCreateDelivery">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 转采购选择通知人 -->
    <el-dialog title="" center :visible.sync="seletUserVisible" width="25%" :close-on-click-modal="false">
      <PageHeader class="m-top-10" :border="false" title="选择部门科室" center />
      <div v-loading="fullscreenLoading" class="rolebody">
        <el-tabs v-model="selectUseractiveName">
          <el-tab-pane v-if="isshow" label="成员" name="user" />
          <el-tab-pane v-if="isshow" label="部门" name="dept" />
          <el-tab-pane v-if="isshow" label="角色" name="role" />
        </el-tabs>
        <div v-if="selectUseractiveName === 'user'" class="selectuser">
          <el-input
            v-model="selectUserkeywords"
            size="small"
            placeholder="搜索成员"
            prefix-icon="el-icon-search"
          />
          <div class="selectUserTree">
            <el-tree
              ref="allusertree"
              :data="connuserlist"
              node-key="value"
              highlight-current
              :filter-node-method="alluserfilterNode"
            >
              <span slot-scope="{ node, data }" style="width:100%">
                <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
                <span class="deptnamespan">
                  {{ node.label }}</span>
                <!-- nodeType- 0：部门 1：员工 -->
                <span v-if="data.nodeType === 1" class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkeduser(data.ischecked, data)" /></span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
      <el-divider />
      <div slot="footer" class="dialog-footer center">
        <el-button @click="seletUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="doconnuser">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 新建地址 start -->
    <el-dialog top="5vh" :visible="showSetAddress" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <p class="p-left-20 weight">新建地址</p>
        <p class="p-right-10 weight f16 cursor" style="color:#000" @click="closeaddrss('ruleForm')">x</p>
      </div>
      <div class="m-top-20" />
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="省" prop="Province">
          <el-cascader
            v-model="ruleForm.Province"
            size="mini"
            :options="Province"
            style="width:290px"
            @change="ProvincehandleChange"
          />
          <!-- <el-input v-model="ruleForm.Province" size="mini" /> -->
        </el-form-item>
        <el-form-item label="市" prop="City">
          <el-cascader
            v-model="ruleForm.City"
            size="mini"
            :options="City"
            style="width:290px"
            @change="CityhandleChange1"
          />
          <!-- <el-input v-model="ruleForm.City" size="mini" /> -->
        </el-form-item>
        <el-form-item label="区" prop="Area">
          <el-cascader
            v-model="ruleForm.Area"
            size="mini"
            :options="Area"
            style="width:290px"
            @click="AreahandleChange2"
          />
          <!-- <el-input v-model="ruleForm.Area" size="mini" /> -->
        </el-form-item>
        <el-form-item label="地址" prop="Address">
          <el-input v-model="ruleForm.Address" size="mini" />
        </el-form-item>
        <el-form-item label="地址编码" prop="AddressCode">
          <el-input v-model="ruleForm.AddressCode" size="mini" />
        </el-form-item>
        <el-form-item label="收货部门" prop="DeptName">
          <el-input v-model="ruleForm.DeptName" size="mini" />
        </el-form-item>
        <el-form-item label="联系人" prop="ContactName">
          <el-input v-model="ruleForm.ContactName" size="mini" />
        </el-form-item>
        <el-form-item label="联系电话" prop="ContactTel">
          <el-input v-model="ruleForm.ContactTel" size="mini" />
        </el-form-item>
        <el-form-item label="地址用途" prop="AddrType" class="is-required">
          <el-checkbox-group v-model="AddrType" @change="handleCheckedCitiesChange">
            <el-checkbox label="收货地址" />
            <el-checkbox label="寄票地址" />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="是否默认" prop="DefaultFlag">
          <el-radio v-model="ruleForm.DefaultFlag" :label="1">是</el-radio>
          <el-radio v-model="ruleForm.DefaultFlag" :label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="上传附件" prop="Filepath">
          <input id="file" type="file" name="file" multiple="multiple" @change="handleFileChange">
        </el-form-item>
        <el-form-item>
          <el-button type="info" size="mini" @click="closeaddrss('ruleForm')">取消</el-button>
          <el-button type="primary" size="mini" @click="submitAddress('ruleForm')">新增地址</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 新建地址 end-->
    <!-- 选择地址 -->
    <el-dialog class="DepartmentBox" title="选择地址" center :visible.sync="seletaddressVisible" width="60%" :close-on-click-modal="false">
      <!-- <el-button size="small" type="primary" round plain @click="creatArdess">新建地址</el-button> -->
      <div class="p-tb-20" style="height:200px;overflow: auto;">
        <div v-for="(item, index) in addressList" :key="index" class="seladdress flex j-between">
          <div class="addressleft flex j-between" style="flex:1">
            <div><span>
              {{ item.FullAddress }}
            </span></div>
          </div>
          <div class="addressright p-lr-10">
            <!-- <el-button  size="mini" round plain>编辑</el-button> -->
            <!-- <el-button  size="mini" round plain>删除</el-button>
                  <el-button  size="mini" v-if="!item.isdefault" round plain>设为默认地址</el-button> -->
            <!-- v-if="item.Status === 1" -->
            <el-button class="ModelBttton1-white-24" type="primary" round plain @click="confirmCheckedAddress(item)">选择</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import orderDetails from '@/minxin/orderMananger/orderHandle.js'
export default orderDetails
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/main.scss';
@import '@/style/order.scss';
@import '@/style/selectUser.scss';
</style>
<style>
.shdz .el-form-item__content {
  width: 62%;
}
</style>
